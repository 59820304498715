<template>
  <div>
    <div style="padding-left: 23px; font-weight: bold; line-height: 60px; font-size: 20px;background:#e9f7ff;color:#0580AD;">劝导单信息</div>
    <div class="mes_Row">
      <label  style="padding-left: 23px;">劝导类型：</label>
      <input type="text"  v-model="QdInfo.QDDX_LX" readonly="readonly" />
    </div>
    <div class="mes_Row">
      <label style="padding-left: 23px;">劝导对象：</label>
      <input type="text" v-model="QdInfo.QDDX_MC" readonly="readonly" />
    </div>
    <div class="mes_Row">
      <label  style="padding-left: 23px;">执法类别：</label>
      <input type="text" v-model="QdInfo.ZFLB_Name" readonly="readonly" />
    </div>
    <div class="mes_Row">
      <label  style="padding-left: 23px;">处理结果：</label>
      <input type="text"  v-model="QdInfo.CLJG_Name"  readonly="readonly" />
    </div>
    <div class="mes_Row" style="height: 150px; position: relative; display: none" id="div_Remark">
      <label style="position: absolute; top: 55px; padding-left: 23px;">备注：</label>
      <textarea  v-model="QdInfo.Remark" style="width: 75%; height: 150px; resize: none; position: absolute; right: 2px; padding: 15px 5px 15px 13px; line-height: 1.5em; box-sizing: border-box" readonly="readonly"></textarea>
    </div>

    <div style="padding-left: 23px; background-color: #fff; line-height: 30px;" id="thumb_Pic_QDQ">
      劝导前照片：<br>
      <span v-for="(item, i) in QdInfo.File">
        <van-image
            width="100"
            height="100"
            :src="item.URL"
        />
      </span>
    </div>

    <div class="mes_Row">
      <label style="padding-left: 23px;">所属中队：</label>
      <input type="text"  v-model="QdInfo.ZD_Name" id="txt_Street" readonly="readonly" />
    </div>
    <div class="mes_Row">
      <label  style="padding-left: 23px;">所属网格：</label>
      <input type="text"  v-model="QdInfo.WG_Name" id="txt_Grid" readonly="readonly" />
    </div>
    <div class="mes_Row">
      <label style="padding-left: 23px;">发起人员：</label>
      <input type="text"  v-model="QdInfo.Staff_Name" id="txt_Grid_Management" readonly="readonly" />
    </div>
    <div class="mes_Row">
      <label style="padding-left: 23px;">协同人员：</label>
      <input type="text"  v-model="QdInfo.XTRY" readonly="readonly" />
    </div>
  </div>
</template>

<script>
import {mgop} from "@aligov/jssdk-mgop";
import DES from "@/assets/js/DES";
import {Toast} from "vant";
import Emas from "@/assets/js/Emas";

export default {
name: "mqsbQd",
  data(){
    return{
      token:'',
      ID:'',
      Info:'',
      QdInfo:{
        ID: '',
        QDDX_LX:'' ,
        QDDX_MC: '',
        This_Status: '',
        ZFLB_Name:'' ,
        CLJG_Name: '',
        QDJG: '',
        ZKW: '',
        ZKW_SL:'' ,
        Remark: '',
        ZD_Name:'' ,
        WG_Name:'' ,
        Staff_Name:'' ,
        File: [],
        XTRY: ''
      }
    }
  },
  created() {
    this.token = sessionStorage.getItem("token");
          this.ID=this.$route.query.ID
    this.fetchData()
    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);
  },
  methods:{
  async fetchData(){
    let strData='{"ID":"' + this.ID +'"}';
    //console.log(strData)
    //alert(strData)
    this.Info=DES.encryptDes(strData)
    // console.log(this.Info)
    // let a='{"result":"ok","msg":{"ID":"12","QDDX_LX":"店铺","QDDX_MC":"余姚市马俊拉面店","This_Status":"2","ZFLB_Name":"劝导","CLJG_Name":"警告","QDJG":"已劝导","ZKW":"","ZKW_SL":"","Remark":"","ZD_Name":"凤山中队","WG_Name":"季卫桥社区网格","Staff_Name":"赵瑞山","File":[{"URL":"http://113.31.115.78:8097/UpLoad_IMG/IMG_LOAD/blob_20210602092400360_0.jpg"}],"XTRY":""}}';
    // let data=JSON.parse(a);
    // console.log(data)
    // console.log(data.msg.count)
    // if (data.result=="ok"){
    //   if (data.msg!=''){
    //     this.QdInfo=data.msg
    //     console.log(data.msg)
    //     console.log(this.QdInfo)
    //   }else {
    //   }
    //
    // }
    // return;
    mgop({
      api: "mgop.will.mqsb.SjMqSb", // 必须
      host: "https://mapi.zjzwfw.gov.cn/",
      dataType: "JSON",
      type: "POST",
      data: {token:this.token,type:'6', Info: this.Info },
      appKey: "3n3b3538+2001833223+ekdhqo", // 必须
      onSuccess: (data) => {
        // alert(JSON.stringify(data))
        console.log("mgop run success login : " + JSON.stringify(data));
        if (data.data.result == "ok") {
          if (data.data.msg!=''){
            this.QdInfo=data.data.msg
            console.log(data.data.msg)
            console.log(this.QdInfo)
          }

        }else if(data.data.result == "401"){
          Toast.fail('token失效，请重新登录');
          this.$router.push({
            path: '/index'
          })
        } else {
        }
      },
      onFail: (err) => {
        // alert(JSON.stringify(err))
        console.log("mgop run error. error :  " + JSON.stringify(err));
      },
    });
  }
  }
}
</script>

<style scoped>
@import "../../../assets/css/layui.css";
* {
  margin: 0;
  padding: 0;
  font-family: 'Arial, Helvetica, sans-serif';
}

html,
body {
  height: 100%;
}


label {
  text-align: right;
}

.mes_Row {
  height: 45px;
  line-height: 45px;
  background-color: rgb(251, 252, 254);
  border-bottom: 1px solid rgb(238, 239, 241);
}

input {
  border: none;
  outline: none;
  width: 220px;
  height: 30px;
}


select {
  width: 200px;
  height: 30px;
}

button {
  width: 120px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  border-radius: 3px;
}


ul {
  display: inline-block;
  height: 80px;
  overflow: auto;
}

ul li {
  height: 20px;
  list-style: none;
}

h4 {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  width: 100%;
  color: #fff;
  background: rgb(44, 167, 234);
  position: fixed;
  top: 0;
  z-index: 99;
}

h4  {
  position: absolute;
  left: 10px;
  margin-top: 2px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
</style>